<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue';
import ListModule from './modules/ListModule.vue';
import Mixin from '../libs/Mixin';
interface IState {
    errorMessage: string;
}
export default defineComponent({
    mixins: [Mixin],
    components: {
        ListModule
    },
    setup() {
        const state = reactive<IState>({
            errorMessage: ''
        });

        return {
            ...toRefs(state)
        };
    },
    created() {
        if (this.$store.state.Common.threeDSecure) {
            if (!this.$store.state.Common.threeDSecure.result) {
                this.errorMessage = this.$store.state.Common.threeDSecure.message;
            }
        }
    },
    methods: {
        clearErrorMessage(): void {
            this.errorMessage = '';
        }
    }
});

</script>

<template>
<div>
    <main class="dac-main">
        <article class="dac-article">
            <section class="dac-section">
                <div class="dac-section__inner--lg">
                    <div class="dac-note--alert" v-if="errorMessage">
                        <div class="dac-note__inner">
                            <div class="dac-note__inner--left">
                                <p class="dac-note--alert__text--small" v-html="errorMessage"></p>
                            </div>
                            <a href="javascript: void(0);" class="dac-note__close-btn" @click="clearErrorMessage">x</a>
                        </div>
                    </div><!-- /.dac-note-notice -->
                    <h3 class="dac-heading-point" v-if="router.market === 'sell'">出品中ドメイン一覧</h3>
                    <h3 class="dac-heading-point" v-else>オークション中のドメイン</h3>
                    <ListModule></ListModule>
                </div>
            </section><!-- /.dac-section -->
        </article><!-- /.dac-article -->
    </main><!-- /.dac-main -->
</div>
</template>