import {
    SET_COMMON_DATA,
    SET_COMMON_LOADING,
    SET_MAIN_COMPONENT,
    SET_LIST_DATA,
    SET_ITEM_DATA,
    SET_HISTORY_LIST_DATA,
    SET_RECOMMEND_LIST_DATA,
    SET_VIEW_DATA,
    NEED_AUTH_PAGES,
    END_INTRO,
    SHOW_MODAL,
    HIDE_MODAL,
    SET_WATCH_LIST_DATA,
    SET_BID_LIST_DATA,
    SET_WIN_BID_LIST_DATA,
    SET_OFFER_LIST_DATA,
    SET_RENDER_HIDE_MODAL,
    LOGIN_SUCCESS_EVENT,
    SET_AJAX_PROGRESS,
    SET_END_DATE,
    SET_ERROR_MESSAGE,
    DEFAULT_ERROR_MESSAGE,
    ERROR_NOT_EXIST_ITEM,
    WATCH_EVENT_LOGIN,
    LOGIN_DEFAULT,
    COMPONENT_LOGIN,
    COMPONENT_LIST,
    COMPONENT_ERROR,
    COMPONENT_MY_PAGE,
    COMPONENT_DETAIL,

} from '../../libs/Mutations';

import Api from '../../libs/Api';
import CommonUtil from '../../libs/CommonUtil';

import EventHub from '../../libs/EventHub';

let firstRender: boolean = false;

interface IThreeDSecure {
    result: boolean;
    message: string;
}
export interface IState {
    isLogin: boolean;
    nickname: any;
    cardCheckFlg: any;
    date: any;
    loading: boolean;
    intro: boolean;
    mainComponent: null|string;
    currentModal: any;
    watchEventLoginModal: string;
    shopId: any;
    roleId: any;
    userName: any;
    ajaxProgress: boolean;
    renderHideModal: boolean;
    errorMessage: string;
    firstCharacter: string|null;
    isExistingUser: boolean;
    threeDSecure: IThreeDSecure|null;
    is3DSecureFlg: boolean;
    [key: string]: any
}



export const Common = {
    state: () => ({
        isLogin: false,
        nickname: null,
        cardCheckFlg: null,
        date: null,
        loading: false,
        intro: true,
        mainComponent: null,
        currentModal: null,
        watchEventLoginModal: LOGIN_DEFAULT,
        shopId: null,
        roleId: null,
        userName: null,
        ajaxProgress: false,
        renderHideModal: true,
        firstCharacter: null,
        isExistingUser: false,
        threeDSecure: null,
        is3DSecureFlg: false,
        errorMessage: DEFAULT_ERROR_MESSAGE
    } as IState),
    mutations: {
        [WATCH_EVENT_LOGIN](state, watchEventLoginModal: string) {
            state.watchEventLoginModal = watchEventLoginModal;
        },
        [SET_COMMON_DATA](state, data: IState) {
            Object.keys(data).forEach(key => {
                if (state.hasOwnProperty(key)) {
                    state[key] = data[key];
                }
            });
        },
        [SET_COMMON_LOADING](state, value: boolean) {
            state.loading = value;
        },
        [SET_MAIN_COMPONENT](state, component: string) {
            state.mainComponent = component;
        },
        [END_INTRO](state) {
            try {
                state.intro = false;
            } catch (e) {
                console.log(e);
            }
        },
        [SHOW_MODAL](state, modalName: string) {
            state.currentModal = modalName;
        },
        [HIDE_MODAL](state) {
            state.currentModal = null;
        },
        [SET_RENDER_HIDE_MODAL](state, flag: boolean) {
            state.renderHideModal = flag;
        },
        [SET_AJAX_PROGRESS](state, flag: boolean) {
            state.ajaxProgress = flag;
        },
        [SET_ERROR_MESSAGE](state, message: string) {
            state.errorMessage = message;
        }
    },
    actions: {
        showModal(context, modalName: string): void {
            context.commit(SHOW_MODAL, modalName);
        },
        hideModal(context): void {
            context.commit(HIDE_MODAL);
        },
        showLoading(context): void {
            context.commit(SET_COMMON_LOADING, true);
        },
        hideLoading(context): void {
            context.commit(SET_COMMON_LOADING, false);
        },
        setRenderHideModal(context, flag: boolean): void {
            context.commit(SET_RENDER_HIDE_MODAL, flag);
        },
        setCommonData(context, data: any): void {
            context.commit(SET_COMMON_DATA, data);
        },
        startAjax(context) {
            context.commit(context, SET_AJAX_PROGRESS, true);
        },
        stopAjax(context) {
            context.commit(context, SET_AJAX_PROGRESS, false);
        },
        render(context, router: InterfaceRouter) {
            // モーダルは消す
            if (context.state.renderHideModal) {
                context.commit(HIDE_MODAL);
            }
            // 初期画面表示＆画面のリロード時に

            const request: Api = new Api();
            request.setLoading(false).post(
                'render',
                router,
                (response: any) => {
                    // success
                    let component: string|null = null;

                    if (router.action === 'detail') {
                        component = COMPONENT_DETAIL;
                    } else if (router.action === 'list') {
                        component = COMPONENT_LIST;
                    } else if (router.action === 'mypage') {
                        component = COMPONENT_MY_PAGE;
                    }

                    const data: any = response.data;

                    // EventHubのonイベントが先に発生するので遅延させる
                    setTimeout(() => {
                        EventHub.emit('afterRender', data);
                    }, 200);

                    // ローディング終了
                    if (CommonUtil.isObject(data)) {
                        const renderData: any = data.data;

                        // 共用データ
                        context.commit(SET_COMMON_DATA, renderData.common);
                        // 未ログインで観覧不可はログインフォームを表示する
                        if (!renderData.common.isLogin && NEED_AUTH_PAGES.indexOf(router.action) >= 0) {
                            component = COMPONENT_LOGIN;
                        } else {
                            // リストページ
                            if (router.action === 'list') {
                                context.commit(SET_LIST_DATA, renderData.list);
                            }
                            // 詳細ページ
                            if (router.action === 'detail') {
                                if (CommonUtil.isString(router.itemId)) {
                                    const detailData: any = renderData.detail;
                                    if (CommonUtil.isObject(detailData.item)) {
                                        context.commit(SET_ITEM_DATA, detailData.item);
                                        if (CommonUtil.isObject(detailData.historyList)) {
                                            context.commit(SET_HISTORY_LIST_DATA, detailData.historyList);
                                        }
                                        if (CommonUtil.isArray(detailData.recommendList)) {
                                            context.commit(SET_RECOMMEND_LIST_DATA, detailData.recommendList);
                                        }
                                    } else {
                                        context.commit(SET_ERROR_MESSAGE, ERROR_NOT_EXIST_ITEM);
                                        component = COMPONENT_ERROR;
                                    }

                                } else {
                                    component = COMPONENT_ERROR;
                                }
                            }
                            // マイページ
                            if (router.action === 'mypage') {
                                if (CommonUtil.isObject(renderData.data.watchList)) {
                                    context.commit(SET_WATCH_LIST_DATA, renderData.data.watchList);
                                }
                                if (CommonUtil.isObject(renderData.data.bidList)) {
                                    context.commit(SET_BID_LIST_DATA, renderData.data.bidList);
                                }
                                if (CommonUtil.isObject(renderData.data.winBidList)) {
                                    context.commit(SET_WIN_BID_LIST_DATA, renderData.data.winBidList);
                                }
                                if (CommonUtil.isObject(renderData.data.offerList)) {
                                    context.commit(SET_OFFER_LIST_DATA, renderData.data.offerList);
                                }
                                if (CommonUtil.isString(renderData.data.endDate)) {
                                    context.commit(SET_END_DATE, renderData.data.endDate);
                                }
                                if (CommonUtil.isObject(renderData.data.recommendList)) {
                                    context.commit(SET_RECOMMEND_LIST_DATA, renderData.data.recommendList);
                                }
                            }

                            if (CommonUtil.isObject(renderData.view)) {
                                context.commit(SET_VIEW_DATA, renderData.view);
                            }
                        }

                    }

                    context.commit(END_INTRO);
                    // メインコンポーネントをセットする
                    context.commit(SET_MAIN_COMPONENT, component);
                },
                response =>  {
                    // エラーにする
                    context.commit(SET_MAIN_COMPONENT, COMPONENT_ERROR);
                },
                () => {
                    // 初期ローディングを終了
                    if (!firstRender) {
                        context.commit(END_INTRO);
                    }
                    // ローディングを終了
                    context.commit(SET_COMMON_LOADING, false);
                    firstRender = true;
                }
            );
        },
        async login(context, params: object): Promise<any> {
            // ログイン処理
            const request: Api = new Api();
            context.commit(SET_COMMON_LOADING, true);
            return await request.setLoading(false).post(
                'login',
                params,
                (response: any) => {
                    const data: InterfaceResponseData = response.data;
                    if (data.success) {
                        // csrfトークンを更新する
                        CommonUtil.updateCsrfToken(data.token);
                        context.commit(SET_COMMON_DATA, {
                            isLogin: true,
                            nickname: data.data.user.nickname,
                            userName: data.data.user.userName,
                            cardCheckFlg: data.data.user.cardCheckFlg,
                            shopId: data.data.user.shopId,
                            roleId: data.data.user.roleId,
                            firstCharacter: data.data.user.firstCharacter,
                        });
                    }
                }
            );
        },
        async logout(context): Promise<any> {
            // ログインアウト
            const request: Api = new Api();
            return await request.post(
                'logout',
                {},
                (response: any) => {
                    const data: InterfaceResponseData = response.data;

                    if (data.success) {
                        EventHub.off(LOGIN_SUCCESS_EVENT);
                        // csrfトークンを更新する
                        CommonUtil.updateCsrfToken(data.token);

                        context.commit(SET_COMMON_DATA, {
                            isLogin: false,
                            nickname: null,
                            userName: null,
                            cardCheckFlg: null,
                            shopId: null,
                            firstCharacter: null,
                        });
                    }
                }
            );
        },
        async changePassword(context, params: InterfaceChangePasswordParams): Promise<any> {
            // ログインアウト
            const request: Api = new Api();
            return await request.post(
                'changePassword',
                params,
                (response: any) => {
                    const data: InterfaceResponseData = response.data;
                    if (data.success) {
                        context.commit(SET_COMMON_DATA, {
                            isLogin: true,
                            nickname: data.data.user.nickname,
                            userName: data.data.user.userName,
                            cardCheckFlg: data.data.user.cardCheckFlg,
                            shopId: data.data.user.shopId,
                            firstCharacter: data.data.user.firstCharacter,
                        });
                    }
                }
            );
        },
        setErrorMessage(context, message: string): void {
            context.commit(SET_ERROR_MESSAGE, message);
        },
        setWatchEventLogin(context, watchEventLoginModal: string): void {
            context.commit(WATCH_EVENT_LOGIN, watchEventLoginModal);
        }
    }
}
