<script lang="ts">
import { defineComponent } from 'vue';
import { TRAFFIC_TYPE_AUCTION } from '../../libs/Mutations';
import Mixin from '../../libs/Mixin';
export default defineComponent({
    mixins: [Mixin],
    computed: {
        item(): any {
            return this.$store.state.Item.item;
        },
        trafficTypeAuction(): boolean {
            return this.item.trafficType === TRAFFIC_TYPE_AUCTION;
        }
    }
});
</script>

<template>
<ul class="dac-text-indent--sm">
    <li class="dac-text-indent__item">・私は<a :href="'/agreement/a_ag/registration.html?btn_id=' + router.market + 'auc_detail_confirmation_agree_registcont_201904'" class="dac-text__link--dark" target="_blank" rel="noopener">お名前.comドメイン登録規約</a>、 ならびにドメイン<a :href="'/agreement/a_ag/domain-buysell.html?btn_id=' + router.market + 'auc_detail_confirmation_agree_servicecont_201904'" class="dac-text__link--dark" target="_blank" rel="noopener">売買サービス利用規約</a>、 の全ての事項に同意し、上記内容を申請します。</li>
    <li class="dac-text-indent__item">・ドメイン売買サービスは一旦お申込いただいた場合は、キャンセルできないことに同意します。</li>
    <li class="dac-text-indent__item">・<a :href="'https://internet.gmo/terms/privacy?btn_id=' + router.market + 'auc_detail_confirmation_agree_policy_201904'" class="dac-text__link--dark" target="_blank" rel="noopener">個人情報保護の取り組みについての利用目的に同意します。</a></li>
    <li class="dac-text-indent__item">・請求書及び領収書が発行されないことに同意します。</li>
    <li class="dac-text-indent__item">・当社から発行する通知書は、適格請求書ではございませんのでご承知おきください。</li>
    <template v-if="roleId === '2' && trafficTypeAuction">
        <li class="dac-text-indent__item">・私はGMOグローバルサイン・ホールディングス株式会社（以下、GSHD）が提供する<a href="https://www.gmosign.com/order/pdf/agreement.pdf" class="dac-text__link--dark" target="_blank" rel="noopener">「電子印鑑GMOサイン」の利用約款</a>、<br>
            ならびに<a href="https://www.gmogshd.com/privacy/" class="dac-text__link--dark" target="_blank" rel="noopener">GSHDのプライバシーポリシー</a>に同意し、メールアドレスがGSHDに提供されることを承諾します。</li>
        <li class="dac-text-indent__item">※お名前.comでは、電子署名などにご活用いただける「電子印鑑GMOサイン」の無償版アカウントをあわせてご提供しております。<br>
            当サービスをお申込み後、「電子印鑑GMOサイン」のアカウント情報をメールにてお送りします。<br>
            詳しくは<a href="https://www.gmosign.com/" class="dac-text__link--dark" target="_blank" rel="noopener">電子印鑑ならGMOサイン</a>をご参照ください。</li>
    </template>
</ul>
</template>