<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue';
//import VueRecaptcha from 'vue-recaptcha';
import EventHub from '../../libs/EventHub';
import CommonUtil from '../../libs/CommonUtil';

import { LOGIN_SUCCESS_EVENT } from '../../libs/Mutations';
import Mixin from '../../libs/Mixin';

interface IState {
    loginId: string,
    loginPassword: string,
    errors: any,
    showRecaptcha: boolean,
    recaptchaToken: string,
}

export default defineComponent({
    mixins: [Mixin],
    components: {
        //VueRecaptcha
    },
    setup() {
        const state = reactive<IState>({
            loginId: '',
            loginPassword: '',
            errors: {},
            showRecaptcha: false,
            recaptchaToken: '',
        });

        return {
            ...toRefs(state)
        };
    },
    mounted() {
        CommonUtil.doFixed(true);
    },
    unmounted() {
        CommonUtil.doFixed(false);
    },
    methods: {
        submit(e: Event): void {
            e.preventDefault();
            this.errors = {};
            if (this.loginId === null || this.loginId.trim().length === 0) {
                this.errors.loginId = 'お名前IDは必須です。';
            }
            if (this.loginPassword === null || this.loginPassword.trim().length === 0) {
                this.errors.loginPassword = 'パスワードは必須です。';
            }

            // エラーがない場合
            if (Object.keys(this.errors).length === 0) {
                if (this.showRecaptcha) {
                    (this.$refs.recaptcha as any).execute();
                } else {
                    this.doLogin();
                }
            }
        },
        onCaptchaVerified(recaptchaToken: any): void {
            this.recaptchaToken = recaptchaToken;
            this.doLogin();
            (this.$refs.recaptcha as any).reset();
        },
        onCaptchaExpired(): void {
            this.recaptchaToken = '';
            (this.$refs.recaptcha as any).reset();
        },
        close(e: MouseEvent): void {
            e.preventDefault();
            this.$store.dispatch('hideModal');
            EventHub.off(LOGIN_SUCCESS_EVENT);
        },
        doLogin(): void {
            this.$store.dispatch('showLoading');

            const params: any = {
                loginId: this.loginId,
                loginPassword: this.loginPassword
            };

            if (this.showRecaptcha) {
                params.recaptchaToken = this.recaptchaToken;
            }

            this.$store.dispatch('login', params).then(response => {
                const data: any = response.data;
                if (!data.success) {

                    this.$store.dispatch('hideLoading');
                    if (CommonUtil.isObject(data.errorPoint)) {
                        Object.keys(data.errorPoint).forEach(key => {
                            if (typeof this[key] !== 'undefined') {
                                this.errors[key] = data.errorPoint[key];
                            }
                        });
                        console.log(this.errors);
                    }
                    // パスワード変更対象
                    if (data.changedPassword === false) {
                        this.$store.dispatch('showModal', 'passwordChange');
                    } else {
                        // Todo:一旦、reCAPTCHAをOFFにする
                        // this.showRecaptcha = true;
                    }

                } else {
                    if (EventHub.hasEvent(LOGIN_SUCCESS_EVENT)) {
                        this.$store.dispatch('render', this.router).then(response => {
                            EventHub.emit(LOGIN_SUCCESS_EVENT);
                        });
                    } else {
                        this.$store.dispatch('render', this.router);
                    }
                }
            }).catch(response => {
                this.$store.dispatch('hideLoading');
            });
        },
        fireEventLogin(eventId: string): void {
            const watchEventLoginModal: string = this.$store.state.Common.watchEventLoginModal;
            if(this.router.action === 'detail' && watchEventLoginModal === 'loginBid') {
                if(this.router.market === 'jp') {
                    CommonUtil.events('event575');
                } else if(this.router.market === 'other') {
                    CommonUtil.events('event576');
                }
            }
            if (this.router.market === 'jp' && watchEventLoginModal === 'loginWatching') {
                CommonUtil.events(eventId);
            } else if (this.router.market === 'jp' && watchEventLoginModal === 'loginBid') {
                const eventMap: { [key: string]: string } = {
                    'event552': 'event567',
                    'event553': 'event568',
                    'event554': 'event569',
                    'event555': 'event570'
                };
                CommonUtil.events(eventMap[eventId] || eventId);
            }
        }
    }
});
</script>

<template>
<div>
    <aside class="dac-modal-emphasis--login is-fixed">
        <h3 class="dac-modal-emphasis__heading">ログイン
            <a
                href="javascript:void(0)"
                class="dac-modal-emphasis__close"
                @click="close($event);onclickEvar('eVar10', router.market + 'auc_' + pageName + '_loginmodal_close_onclick_201904')"
            ></a>
        </h3>
        <div class="dac-modal-emphasis--login__content">
            <div class="dac-modal-layout--border">
                <div class="dac-modal-layout__item">
                    <div class="dac-modal-layout__sub">
                        <form method="post" @submit="submit">
                            <div class="dac-form">
                                <label class="dac-form-input__label" for="loginId"><span class="dac-form-input__text">お名前ID（会員ID）</span></label>
                                <input type="text" name="loginId" id="loginId" v-model="loginId" placeholder="お名前ID(会員ID)" class="dac-form-input">
                                <p class="dac-text--sm" v-if="errors.loginId"><span class="dac-text__alert">@{{ errors.loginId }}</span></p>
                                <label class="dac-form-input__label" for="loginPassword"><span class="dac-form-input__text">パスワード</span></label>
                                <input type="password" name="loginPassword" id="loginPassword" v-model="loginPassword" placeholder="パスワード" class="dac-form-input">
                                <p class="dac-text--sm" v-if="errors.loginPassword"><span class="dac-text__alert">@{{ errors.loginPassword }}</span></p>
                            </div><!-- /.dac-form -->
                            <!--<VueRecaptcha
                                v-if="showRecaptcha"
                                ref="recaptcha"
                                :sitekey="recaptchaSiteKey"
                                badge="inline"
                                size="invisible"
                                @verify="onCaptchaVerified"
                                @expired="onCaptchaExpired"
                            ></VueRecaptcha>-->
                            <div class="dac-modal__btn">
                                <button
                                    class="dac-btn-submit--lg"
                                    type="submit"
                                    @click="fireEventLogin('event553');onclickEvar('eVar10', router.market + 'auc_' + pageName + '_loginmodal_loginbutton_onclick_201904')"
                                >ログイン</button>
                            </div><!-- /.dac-modal__btn -->
                        </form>
                        <div class="dac-modal__footer">
                            <ul class="dac-text-indent--sm">
                                <li class="dac-text-indent__item">・
                                    <a
                                        class="dac-text__link"
                                        :href="'https://navi.onamae.com/auth/idreminder?btn_id=' + router.market + 'auc_' + pageName + '_loginmodal_idreminder_201904'"
                                        target="_blank" rel="noopener"
                                        @click="fireEventLogin('event554')"
                                    >
                                        お名前IDを忘れた方はこちら
                                    </a>
                                </li>
                                <li class="dac-text-indent__item">・
                                    <a
                                        class="dac-text__link"
                                        :href="'https://navi.onamae.com/auth/pwreminder?btn_id=' + router.market + 'auc_' + pageName + '_loginmodal_pwreminder_201904'"
                                        target="_blank" rel="noopener"
                                        @click="fireEventLogin('event555')"
                                    >
                                        パスワードを忘れた方はこちら
                                    </a>
                                </li>
                            </ul>
                        </div><!-- /.dac-modal__footer -->
                    </div>
                </div><!-- /.dac-modal-layout__item -->
                <div class="dac-modal-layout__item">
                    <div class="dac-modal-layout__sub">
                        <p class="dac-text">お名前ID(会員ID)をお持ちでない方</p>
                        <div class="dac-modal__btn">
                            <a
                                :href="getSignUpUrl + '&btn_id=' + router.market + 'auc_' + pageName + '_loginmodal_memberregistrationbutton_201904'"
                                target="_blank"
                                rel="noopener"
                                class="dac-btn-primary--xl"
                                @click="fireEventLogin('event552')"
                            >お名前.com会員登録(無料)</a>
                        </div><!-- /.dac-modal__btn -->
                    </div>
                </div><!-- /.dac-modal-layout__item -->
            </div><!-- /.dac-modal-layout -->
        </div><!-- /.dac-modal-emphasis__content -->
    </aside><!-- /.dac-modal-emphasis -->
    <div class="dac-modal__overlay is-fixed" @click="close"></div>
</div>
</template>
