// @ts-ignore
import Cookies from "js-cookie";

import CryptoJS from 'crypto-js';

let uniqidSeed: number = Math.floor(Math.random() * 0x75bcd15);

type TSameSite = 'strict' | 'lax' | 'none';

let globalData: any = Object.prototype.toString.call(GLOBAL_DATA) === '[object Object]' ? GLOBAL_DATA : {};

const csrfTokenElement: HTMLMetaElement = document.head!.querySelector('meta[name="csrf-token"]') as HTMLMetaElement;

const scrToggle = (e: Event) => {
    e.preventDefault();
};

let isFixed: boolean = false;

// 暗号化あり
if (globalData.encrypt) {
    const csrfToken: string = csrfTokenElement.content;
    const CryptoJSAesJson: InfterfaceCryptoJSAesJson = {
        stringify(cipherParams) {
            const jsonData: any = {
                ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64)
            };
            if (cipherParams.iv) {
                jsonData.iv = cipherParams.iv.toString();
            }
            if (cipherParams.salt) {
                jsonData.s = cipherParams.salt.toString();
            }
            return JSON.stringify(jsonData);
        },
        parse(jsonStr) {
            const jsonData: any = JSON.parse(jsonStr);
            const cipherParams: any = CryptoJS.lib.CipherParams.create({
                ciphertext: CryptoJS.enc.Base64.parse(jsonData.ct)
            });
            if (jsonData.iv) {
                cipherParams.iv = CryptoJS.enc.Hex.parse(jsonData.iv);
            }
            if (jsonData.s) {
                cipherParams.salt = CryptoJS.enc.Hex.parse(jsonData.s);
            }
            return cipherParams;
        }
    };

    globalData = JSON.parse(CryptoJS.AES.decrypt(JSON.stringify(globalData), csrfToken, { format: CryptoJSAesJson }).toString(CryptoJS.enc.Utf8));
}

try {
    const globalDataElement: HTMLElement = document.head!.querySelector('#globalData') as HTMLElement;
    globalDataElement.parentNode!.removeChild(globalDataElement);
} catch (e) {

}

if (globalData.environment !== 'production') {
    console.log('Global Data:', globalData);
}

export default class {
    static get globalData(): InterfaceGlobalData {
        return globalData;
    }

    static get router(): InterfaceRouter {
        return globalData.router;
    }

    static get csrfToken(): string {
        return csrfTokenElement.content;
    }

    static get isMobile(): boolean {
        return globalData.isMobile;
    }

    static get environment(): string {
        return globalData.environment;
    }

    static get currentYear(): string {
        return globalData.currentYear;
    }

    static get evarContainer(): string {
        if (this.isMobile) {
            return 'gmoinonamaecomsmartphone';
        }
        return 'gmoinonamaecom';
    }

    static isArray(value: any): boolean {
        return Object.prototype.toString.call(value) === '[object Array]';
    }

    static isObject(value: any): boolean {
        return Object.prototype.toString.call(value) === '[object Object]';
    }

    static isFunction(value: any): boolean {
        return Object.prototype.toString.call(value) === '[object Function]';
    }

    static isString(value: any): boolean {
        return typeof value === 'string' || value instanceof String;
    }

    static uniqid(prefix: string = '', moreEntropy = false) {
        let retId: string = prefix;
        uniqidSeed++;
        const getTime: string = (new Date().getTime() / 1000).toString();

        const formatSeed: Function = (seed: string, reqWidth: number): string => {
            seed = parseInt(seed, 10).toString(16);
            if (reqWidth < seed.length) {
                return seed.slice(seed.length - reqWidth);
            }
            if (reqWidth > seed.length) {
                return Array(1 + (reqWidth - seed.length)).join('0') + seed;
            }
            return seed;
        };

        retId += formatSeed(parseInt(getTime, 10), 8);
        retId += formatSeed(uniqidSeed, 5);
        if (moreEntropy === true) {
            retId += (Math.random() * 10).toFixed(8).toString();
        }
        return retId;
    }

    static sitePath(): string {
        const splitPathname: string[] = location.pathname.replace(/^\//, '').replace(/\/{2,}/g, '').split('/');
        return '/' + splitPathname[0];
    }
    static abTestResult(abKey: string): any {
        if (this.isObject(globalData.abTest)) {
            if (globalData.abTest.hasOwnProperty(abKey)) {
                return globalData.abTest[abKey];
            }
        }
        return null;
    }

    static updateCsrfToken(csrfToken: string): void {
        csrfTokenElement.content = csrfToken;
    }

    static events(event: string): void {
        let _s: any = s_gi(this.evarContainer);
        _s.linkTrackVars = 'events';
        _s.linkTrackEvents = event;
        _s.events = event;
        if (typeof _s.tl === 'function') {
            try {
                _s.tl(true, 'o', this.router.market + 'auction');
                if (globalData.environment !== 'production') {
                    try {
                        console.log('fired ' + event + '.');
                    } catch (e) {
                        // 何もしない
                    }
                }
            } catch (e) {
                console.log('fail ' + event);
            }

        }

    }

    static evar(name: string, value: string): void {
        let _s: any = s_gi(this.evarContainer);
        _s.linkTrackVars = name;
        _s[name] = value;
        if (typeof _s.tl === 'function') {
            try {
                _s.tl(true, 'o', this.router.market + 'auction');
                if (globalData.environment !== 'production') {
                    try {
                        console.log('fired ' + name + ':' + value);
                    } catch (e) {
                        // 何もしない
                    }
                }
            } catch (e) {
                console.log('fail ' + name + ':' + value);
            }
        }
    }

    static evarAndEvents(events: any, evars: any): void {
        let _s: any = s_gi(this.evarContainer);
        let linkTrackVars: string = 'events';
        if (
            this.isObject(evars) &&
            Object.keys(evars).length > 0
        ) {
            linkTrackVars += ',' + Object.keys(evars).join(',');
        }
        const joinEvents: string = events.join(',');
        _s.linkTrackVars = linkTrackVars;
        _s.linkTrackEvents = joinEvents;
        _s.events = joinEvents;
        if (this.isObject(evars)) {
            Object.keys(evars).forEach(function (key) {
                _s[key] = evars[key];
            });
        }
        if (typeof _s.tl === 'function') {
            try {
                _s.tl(true, 'o', this.router.market + 'auction');
                if (globalData.environment !== 'production') {
                    try {
                        console.log('- start ----');
                        if (this.isObject(evars)) {
                            Object.keys(evars).forEach(function (key) {
                                console.log('fired ' + key + ':' + evars[key]);
                            });
                        }
                        events.forEach(function (event: string) {
                            console.log('fired ' + event + '.');
                        });
                        console.log('- end ----');
                    } catch (e) {
                        // 何もしない
                    }
                }
            } catch (e) {
                try {
                    console.log('- start ----');
                    if (this.isObject(evars)) {
                        Object.keys(evars).forEach(function (key) {
                            console.log('fail ' + key + ':' + evars[key]);
                        });
                    }
                    events.forEach(function (event: string) {
                        console.log('fail ' + event + '.');
                    });
                    console.log('- end ----');
                } catch (e) {
                    // 何もしない
                }
            }
        }

    }

    static products(product: string): void {
        let _s: any = s_gi(this.evarContainer);
        _s.linkTrackVars = 'products';
        _s.linkTrackEvents = product;
        _s.products = product;
        if (typeof _s.tl === 'function') {
            try {
                _s.tl(true, 'o', this.router.market + 'auction');
                if (globalData.environment !== 'production') {
                    try {
                        console.log('fired ' + 's.products:' + product);
                    } catch (e) {
                        // 何もしない
                    }
                }
            } catch (e) {
                console.log('fail ' + 's.products:' + product);
            }
        }

    }

    static eventsAndProduct(event: any, product: any): void {
        let _s: any = s_gi(this.evarContainer);
        _s.linkTrackVars = 'products, events';
        _s.linkTrackEvents = event;
        _s.events = event;
        _s.products = product;
        if (typeof _s.tl === 'function') {
            try {
                _s.tl(true, 'o', this.router.market + 'auction');
                if (globalData.environment !== 'production') {
                    try {
                        console.log('- start ----');
                        console.log('fired ' + event + '.');
                        console.log('fired ' + 's.products:' + product);
                        console.log('- end ----');
                    } catch (e) {
                        // 何もしない
                    }
                }
            } catch (e) {
                console.log('- start ----');
                console.log('fail ' + event + '.');
                console.log('fail ' + 's.products:' + product);
                console.log('- end ----');
            }
        }

    }

    static getCookie(name: string): undefined | string {
        return Cookies.get(name);
    }

    static setCookie(name: string, value: string, expires: number = 365, sameSite: TSameSite = 'lax'): void {
        const path = '/' + location.pathname.replace(/^\//, '').split('/')[0];
        const domain = location.hostname;

        Cookies.set(name, value, {
            secure: true,
            domain: domain,
            expires: expires,
            sameSite: sameSite,
            path: path
        });
    }

    static doFixed(value: boolean): void {
        const body: HTMLBodyElement = document.body as HTMLBodyElement;
        const fixedClassName: string = 'is-fixed';
        const scrEvent: string = `onwheel` in document ? `wheel` : `onmousewheel` in document ? `mousewheel` : `DOMMouseScroll`;

        if (value) {
            if (!isFixed) {
                body.classList.add(fixedClassName);
                body.addEventListener( scrEvent, scrToggle, {
                    capture: false,
                    passive: false
                });
                // TODO: passive: false
                body.addEventListener(`touchstart`, scrToggle, false);
                isFixed = true;
            }

        } else {
            body.classList.remove(fixedClassName);
            body.removeEventListener(scrEvent, scrToggle, false);
            body.removeEventListener(`touchstart`, scrToggle, false);
            isFixed = false;
        }
    }
}
